.photogallerySpace{
    padding: 200px 5% 120px 5% !important;
}
.photogallerythumbnail{
    max-width: 100%;
    height: auto;
    border-radius: 6px
}
.photogallerythumbnail:hover{
    cursor: pointer;
}
.topspcdsk{
    margin-top: 32px;
}
.galleryMainHead{
  font-family: 'Georama';
  font-weight: 700;
    font-size: 44px;
    line-height: 120%;
    color: #fff254;
    text-align: center;
    margin-bottom: 22px;
}
.gallerySpace{
    margin-top: 42px;
}
.yearTxt{
  font-family: 'Georama';
  font-weight: 700;
    font-style: normal;
    font-size: 52px;
    line-height: 120%;
    color: #fff;
    text-align: center;
    margin-bottom: 32px; 
}
.spacePhotos{
    margin-bottom: 32px;
}
.secondGallerySpace{
    margin-top: 62px;
}
@media screen and (max-width: 767px) {
.mobileSpacePhotos{
    margin-bottom: 30px;
}
.yearTxt{
    font-size: 61px;
    line-height: 88px;
}
.galleryMainHead{
    font-size: 42px;
    line-height: 72px;
}
}