.bannerMainAbout{
    margin-bottom: 62px;
}
.about_main_banner{
    height: 100%;
    width: 100%;
}
.aboutPage{
    padding: 0px 5% 120px 5%;
}
.mBottom{
    margin-bottom: 12px !important;
}
.about_para{
    font-family: 'Georama';
  font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #fff;
  }
  .headingAbout{
  font-family: 'Georama';
  font-weight: 700;
    font-size: 38px;
    line-height: 80%;
    color: #fff254;
    margin-bottom: 32px;
    text-transform: uppercase;
  }
  .desktop{
    display: block;
}
.mobile{
    display: none;
}
.about_para_bold{
  font-family: 'Georama';
  font-weight: 700;
    font-size: 42px;
    line-height: 80%;
    color: #fff;
    margin-top: 42px;
    margin-bottom: 28px;
    text-transform: uppercase;
}
/* Media query starts */
@media screen and (max-width: 767px) {
    .aboutPage{
        padding: 0px 5% 80px 5%;
    }
    .desktop{
        display: none;
    }
    .mobile{
        display: block;
    }
    .about_main_banner{
        margin-top: 52px;
    }
    .about_para{
        font-size: 16px;
      }
      .headingAbout{
        font-size: 32px;
        margin-bottom: 10px;
      }
      .bannerMainAbout{
        margin-bottom: 42px;
    }
    .about_para_bold{
      font-family: 'Georama';
  font-weight: 700;
        font-size: 42px;
        line-height: 80%;
        color: #fff;
        margin-top: 30px;
        margin-bottom: 24px;
    }
}
@media screen and (min-width: 767px) and (max-width: 1023px) {
}
@media screen and (min-width: 1024px){
}