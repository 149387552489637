.mt52{
    margin-top: 52px;
}
.mbspace{
    margin-bottom: 82px;
}
.mt72{
    margin-top: 92px;
}
#more {display: none;}
#more1 {display: none;}
#more2 {display: none;}
#more3 {display: none;}
.middleAlign{
    justify-content: center;
    align-items: center;
    display: flex;
}
.alignMiddle{
    justify-content: center;
    align-items: center;
    display: flex;
}
.aboutartist{
    font-family: 'Georama';
  font-weight: 400;
    font-size: 17px;
    line-height: 150%;
    color: #fff;
    margin-top: 0px;
}
.stylereadmrebtn{
    font-family: 'Georama';
  font-weight: 500;
    padding: 4px 12px;
    margin-top: 10px;
    font-size: 15px;
    background-color: #F0E620;
    color: #000;
    border: 0px;
    border-radius: 20px;
}
.aboutartistMain{
    font-family: 'Georama';
  font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    color: #000;
    margin-top: 0px;
}
.spacearl{
    margin: 60px 0px;
}
.oneProp{
    position: absolute;
    bottom:-50px;
    max-width: 100%;
    height: auto;
    z-index: 12;
}
.twoProp{
    position: absolute;
    bottom:-74px;
    max-width: 100%;
    right: 0px;
    height: 120px;
    z-index: 12;
}
.threeProp{
    position: absolute;
    bottom:-50px;
    max-width: 100%;
    right: 0px;
    height: auto;
    z-index: 12;
}
.artistnamem{
     font-family: 'Georama';
    font-weight: 600;
    font-style: italic;
    font-size: 34px;
    line-height: 100%;
    margin-bottom: 22px;
    color: #fff254;
    margin-top: 24px;
}
.artistnamemMain{
     font-family: 'Georama';
    font-weight: 800;
    font-size: 42px;
    line-height: 100%;
    margin-bottom: 22px;
    color: #000;
    margin-top: 24px;
}
.innercardO{
    background-color: #00A0DD;
}
.innercardT{
    background-color: #D30E68;
}
.innercardth{
    background-color: #F0E620;
}
.artist_info_main{
    padding: 160px 5% 120px 5%;
}
.ar_img, .new_img_ar{
    max-width: 100%;
    height: auto;
}
.new_img_ar{
    max-width: 100%;
    height: 380px !important;
}
button.accordion-button.collapsed {
    border: 1px solid #0000 !important;
    padding: 12px 20px;
    font-size: 18px;
    color: #fff;
    margin: 20px 0px;
    background-color: #000;
    font-style: italic;
    font-family: 'Georama';
  font-weight: 700;
}
button.accordion-button {
    border: 1px solid #0000 !important;
    padding: 12px 20px;
    font-size: 18px;
    background-color: yellow;
    font-family: 'Georama';
  font-weight: 700;
}
@media screen and (max-width: 767px) {
    .artist_info_main{
        padding: 160px 5% 120px 5%;
    }
    .artistnamem{
        font-size: 34px;
        margin-top: 24px;
        margin-bottom: 14px;
    }
    .spaceMobileB{
        margin-bottom: 72px;
    }
    .aboutartist{
        font-family: 'Georama';
  font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        margin-top: 0px;
    }
    .alignMiddle ,.mbspace{
        margin-bottom: 42px;
    }
    .center{
        text-align: left !important;
    }
}