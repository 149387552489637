.navbarr{
    background-color: #ffdd0000;
    position: fixed;
    top: 0px;
    /* height: 100px; */
    width: 100%;
    padding: 12px 5% 16px 5% !important;
    z-index: 9999999999;
    transition: .4s all ease-in-out;
}
  .logoHeader{
    max-width: 100%;
    height: 100px;
    margin-left: 20px;
  }
.navbarr.colorChange{
    background-color: #000000;
    position: fixed;
    padding-top: 20px !important;
    top: 0px;
    height: 120px;
    transition: .4s all ease-in-out;
}
/* .customNavbarHead{
    background-color: #000 !important;
    padding: 24px 5% 8px 5% !important;
} */
a.navbar-brand{
    color: #fff !important;
    font-family: 'Georama';
  font-weight: 500;
    font-style: normal;
    font-size: 24px;
    line-height: 105%;
    letter-spacing: 0.03em;
}
.navbarCustom{
  font-family: 'Georama';
  font-weight: 700;
    font-style: normal;
    font-size: 18px;
    line-height: 105%;
    letter-spacing: 0.03em;
    color: #FFFFFF !important;
    margin-right: 22px;
}
.navbarCustom:hover{
  font-family: 'Georama';
  font-weight: 700;
    color: #FF4D1F !important;
    text-decoration: underline !important;
}
.navbarBottom{
    width: 110vw;
    margin-top: 40px;
    position: fixed;
    top: 48px;
    z-index: 12;
    left: -10px;
}
.cntBtn{
    background-color: #FFFFFF;
    border: 1px solid #000000;
    box-shadow: 2px 2px 0px #FF4D1F;
    border-radius: 40px;
    padding: 8px 28px !important;
    color: #000 !important;
    margin-right: 0px !important;
}
.cntBtn:hover{
    background-color: #FF4D1F;
    box-shadow: 0px 0px 0px #000;
    transition: .3s all ease-in;
    color: #fff !important;
    text-decoration: none !important;
}
.navbarLogo{
    max-width: 100%;
    height: 100px;
    position: fixed;
    top: 10px;

}
/* .navbarr.colorChange{
    background-color: #000000;
    position: fixed;
    top: 0px;
    height: 60px;
    transition: .4s all ease-in-out;
} */
.run {
        background-size: 1500px;
        background-repeat: repeat-x;
        width: 100%;
        height: 20px;
        /* margin-top: 87px; */
        position: fixed;
        top: 87px;
        z-index: 12;
    }

.run.slideright-l {
    background-image: url('../Assets/navbarBottom.svg');
}
.run.slideright-l {
    animation: leftRunning 300s infinite linear;
    -webkit-animation: leftRunning 300s infinite linear;
    background-image: url('../Assets/navbarBottom.svg');
}
.navbar-light .navbar-toggler-icon {
    background-image: url('../Assets/menu.svg') !important;
    margin-right: -15px;
}
@keyframes leftRunning {
    0%{
        background-position: -10000px;
    }
    100%{
        background-position: 0px;
    }
}
/* Media query starts */
@media only screen and (max-width: 600px) {
    a.navbar-brand{
        color: #fff !important;
        font-family: 'Georama';
  font-weight: 500;
        font-style: normal;
        font-size: 22px;
        line-height: 105%;
        letter-spacing: 0.03em;
    }
    .navbarBottom{
        top: 26px;
        height: 13px;
        width: auto;
    }
    .centerAlignNavs{
        text-align: left !important;
        padding: 12px 0px 32px 0px;
    }
    .customNavbarHead{
        padding: 16px 5% 4px 5% !important;
    }
    .cntBtn{
        width: fit-content;
    }
    .navbarLogo{
        max-width: 100%;
        height: 58px;
    }
    .navbar-light .navbar-toggler-icon {
        background-image: url('../Assets/menu.svg') !important;
        margin-right: -15px;
        height: 30px !important;
    }
    .run {
        background-size: 1500px;
        background-repeat: repeat-x;
        width: 100%;
        height: 20px;
        /* margin-top: 87px; */
        position: fixed;
        top: 66px;
        z-index: 12;
    }
   
    .navbar{
        background-color: #000 !important;
        padding: 20px 5% 20px 5% !important;
        /* height: 60px; */
    }
    .navbarr.colorChange{
        background-color: #000000;
        position: fixed;
        top: 0px;
        height: auto !important;
        transition: .4s all ease-in-out;
    }
    
}