.culturalOutreachPage{
    padding: 62px 5% 120px 5%;
}
.caMainHead{
  font-family: 'Georama';
  font-weight: 700;
    font-size: 180px;
    line-height: 76%;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    margin-bottom: 82px;
}
.caSubHead{
  font-family: 'Georama';
  font-weight: 700;
    font-size: 36px;
    line-height: 76%;
    letter-spacing: 0.03em;
    color: #fff254;
    margin-bottom: 28px;
    text-align: left;
    text-transform: uppercase;
}
.bold{
    font-family: 'Georama';
  font-weight: 700;
}
.linkCa{
    font-family: 'Georama';
  font-weight: 700;
    color: #fff254;
}
.linkCa:hover{
    color: aqua !important;
}
.ca_para{
    font-family: 'Georama';
    font-weight: '400';
    font-size: 18px;
    font-weight: 400;
    line-height: 150%;
    color: #fff;
  }
  .spaceCa{
    margin-bottom: 62px;
  }
  /* Media query starts */
@media screen and (max-width: 767px) {
    .caMainHead{
        font-size: 80px;
        text-align: left;
        margin-bottom: 42px;
    }
    .culturalOutreachPage{
        padding: 42px 5% 80px 5%;
    }
    .caSubHead{
        font-size: 31px;
        margin-bottom: 10px;
    }
    .ca_para{
        font-size: 16px;
    }
    .bannerMainCO{
        padding-top: 85px;
    }
}
@media screen and (min-width: 767px) and (max-width: 1023px) {
}
@media screen and (min-width: 1024px){
}