.newsContainerMain{
    padding: 160px 5% 120px 5%;
}
.footerSubPages{
    padding: 160px 5% 120px 5%;
}
.newsParagraph{
    font-family: 'Georama';
  font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    color: #fff;
    text-align: left;
}
.italic{
    font-style: italic;
}
.headSub{
    color: #fff254;
    font-family: 'Georama';
  font-weight: 600;
    font-size: 20px;
}
.footerP{
    color: #fff254;
    font-family: 'Georama';
  font-weight: 600;
    font-size: 18px;
}
.footerP:hover{
    color: #fff;
    font-family: 'Georama';
  font-weight: 600;
    font-size: 18px;
}
.newsImgStyle{
    max-width: 100%;
    height: auto;
    margin-bottom: 32px;
}
.posterNews{
    max-width: 100%;
    height: 240px;
    margin-bottom: 52px;
}
.center{
    text-align: center;
}
.bold{
    font-family: 'Georama';
  font-weight: 700;
}
@media only screen and (max-width: 767px) {
    .newsParagraph{
        font-size: 16px;
    }
    .newsContainerMain{
        padding: 80px 5% 80px 5%;
    }
    .footerSubPages{
        padding: 140px 5% 80px 5%;
    }
}