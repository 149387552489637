.bluesArtMain{
    padding: 90px 5% 120px 5%;
    text-align: center;
}
.bluesart_banner{
    max-width: 100%;
    height: auto;
    margin: auto;
}
.content_blues_art{
    margin-top: 100px;
}
.boldnmes{
    font-family: 'Georama';
    font-weight: 700;
    color: #fff254;
margin-bottom: 6px;
}
.artist_name_ba{
  font-family: 'Georama';
  font-weight: 700;
    font-size: 32px;
    line-height: 76%;
    letter-spacing: 0.03em;
    color: #fff254;
    margin-bottom: 16px;
    text-align: left;
}
.link_ba{
    color: #fff254 !important;
    font-family: 'Georama';
  font-weight: 700;
}
.artist_bio_ba{
    font-family: 'Georama';
  font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    color: #fff;
    text-align: left;
}
.artist_poster{
    max-width: 100%;
    height: auto;
}
.space_ba_info{
    margin-bottom: 80px;
    justify-content: center;
    align-items: center;
}
.spaceSingleBa{
    margin-bottom: 60px;
}
/* Media query starts */
@media screen and (max-width: 767px) {
    .bluesArtMain{
        padding: 90px 5% 80px 5%;
        text-align: left;
    }
    .artist_name_ba{
        font-size: 32px;
        margin-bottom: 10px;
    }
    .artist_bio_ba{
        font-size: 16px;
    }
    .content_blues_art{
        margin-top: 80px;
    }
    .artist_poster{
        max-width: 100%;
        height: 420px;
        margin-bottom: 36px;
    }
    .space_ba_info{
        margin-bottom: 48px;
    }
}
@media screen and (min-width: 767px) and (max-width: 1023px) {
}
@media screen and (min-width: 1024px){
}