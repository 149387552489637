/* .MainBanner{
    margin-top: 20px;
} */
.sliderImage{
    width: 100%;
    height: auto;
}
.aboutBlock{
    padding: 100px 5% 0px 5%;
    background-image: url('../Assets/Home/bgabout.png');
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    background-color: #0008f3;
    margin-top: -8px;
    position: relative;
}
.aboutFestivalCard{
    background: #FFFFFF;
    border: 2px solid #000000;
    box-shadow: 8px 8px 0px #000000;
    border-radius: 10px !important;
    padding: 42px 24px 58px 24px;
}

.lineupCard{
    background: #FFFFFF;
    border: 2px solid #000000;
    box-shadow: 8px 8px 0px #000000;
    border-radius: 10px !important;
    padding: 22px 22px 22px 22px;
}
.headingMbf{
  font-family: 'Georama';
  font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    font-size: 62px;
    line-height: 96%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    text-shadow: 4px 8px 4px rgba(0, 0, 0, 0.43);
}
.mySwiperr{
    height: 600px;
}
.tabStyle{
    background: #FFFFFF;
    border: 1px solid #000000;
    box-shadow: 3px 5px 0px #000000;
    border-radius: 35px !important;
    font-family: 'Georama';
  font-weight: 700;
    font-style: normal;
    padding: 16px 32px !important;
    font-size: 18px !important;
    line-height: 23px;
    color: #000000;
    margin-right: 26px;
}
.btnBlue{
    background: #0008f3;
    border: 1px solid #000000;
    box-shadow: 3px 5px 0px #000000;
    border-radius: 35px !important;
    font-family: 'Georama';
  font-weight: 700;
    font-style: normal;
    padding: 16px 32px;
    font-size: 18px;
    line-height: 23px;
    color: #fff;
}
.propsOneStyle{
    max-width: 100%;
    height: 350px;
    margin-top: 40px;
    position: absolute;
    bottom: 0px;
    left: 0px;
}
.spaceTopBtn{
    margin-top: 42px;
}
.btnBlue:hover{
    color: #fff !important;
    box-shadow: 0px 0px 0px #000000;
    text-decoration: none;
    transition: .3s all ease-in-out;
}
.tabStyle:hover{
    box-shadow: 0px 0px 0px #000000;
    transition: .3s all ease-in-out;
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link{
    background-color: #FF4D1F !important;
}

.aboutCardHeading{
  font-family: 'Georama';
  font-weight: 700;
    font-style: normal;
    font-size: 34px;
    line-height: 80%;
    letter-spacing: 0.02em;
    color: #000000;
    margin-bottom: 18px;
}
.comingSoonTxtMobile{
    font-family: 'Georama';
  font-weight: 500;
    font-size: 28px;
    color: #fff;
}
.yellowtxt{
    color: #fff254;
}
.blacktxt{
    color: black;
}
.comingSoonTxtDesktop{
    font-family: 'Georama';
  font-weight: 600;
    font-size: 32px;
    text-align: center;
    color: #ffffff;
}

.aboutCardHeadMobile{
  font-family: 'Georama';
  font-weight: 700;
    font-style: normal;
    font-size: 66px;
    line-height: 80%;
    letter-spacing: 0.02em;
    color: #fff254;
    margin-bottom: 18px;
}
.iconsBlock{
    text-align: center;
}
.connectIcons{
    max-width: 100%;
    height: 48px;
    transition: .3s all ease-in;
}
.connectIcons:hover{
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    transition: .3s all ease-in;
}
.spaceConnectsm{
    margin-right: 32px;
}
.paragraph{
    font-family: 'Georama';
  font-weight: 400;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: #313131;
}
.spaceTabsAbout{
    margin-top: 42px;
    margin-bottom: 100px;
}
.tickerProp{
    max-width: 100%;
    height: 42px;
}
.tickerBlock{
    padding: 24px 0px;
    background-color: #fff254;
    /* border: 2px solid #FF4D1F; */
}
.tickerTxt{
    font-family: 'Georama';
  font-weight: 700;
    font-size: 28px;
    line-height: 30px;
    margin: 0px;
    color: rgb(255, 255, 255);
}
.tickerProp{
    height: 34px;
}
.bandHuntHeadInfo{
  font-family: 'Georama';
  font-weight: 700;
    font-size: 58px;
    text-align: left;
    line-height: 90%;
    letter-spacing: 0.02em;
    color: #fff254;
    margin-bottom: 18px;
}
.bandHuntParaInfo{
    font-family: 'Georama';
  font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    text-align: left;
    margin-bottom: 24px;
    color: rgb(255, 255, 255);
}
.bandHuntParaInfoSub{
    font-family: 'Georama';
  font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    text-align: left;
    margin: 0px;
    color: rgb(255, 255, 255);
}
.bandHuntBlock{
    background-image: url('../Assets/Home/bandHunt.png');
    background-size: cover;
    background-position: center;
    padding: 120px 4% 120px 4%;
    background-attachment: fixed;
}

.stageAnimate{
    max-width: 100%;
    height: auto;
}
.mainStageBlock{
    text-align: center;
    padding-top: 120px;
    padding-bottom: 120px;
    border-bottom: 10px solid #fff254;

}
.bandHuntImgInfo{
    max-width: 100%;
    height: auto;
}
.BandHuntHead{
  font-family: 'Georama';
  font-weight: 700;
    font-size: 100px;
    line-height: 80%;
    margin-bottom: 22px;
    text-align: left;
    color: #FFFFFF;
    }
    .BandHuntSmall{
      font-family: 'Georama';
  font-weight: 700;
        font-style: normal;
        font-size: 80px;
        line-height: 80%;
        text-align: center;
        color: #FFFFFF;
    }
    .landing_banner{
        position: relative;
    }
    .work-muted {
        position: absolute;
        height: 30px;
        width: 30px;
        right: 4vh;
        bottom: 5vh;
      }
      .work-muted:hover{
        cursor: pointer;
      }
.BluesNewsBlock{
    padding: 0px 5% 120px 5%;
    margin-top: -130px;
}
.bandHuntAnimate{
    max-width: 100%;
    height: 300px;
}
.imageNews{
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
}
.headingBeta{
  font-family: 'Georama';
  font-weight: 700;
    font-style: normal;
    font-size: 40px;
    line-height: 120%;
    color: #fff254;
    margin-bottom: 32px;
}

.nav-tabs .nav-link{
    border: none !important;
    background-color: #fff254; 
    border-radius: 24px !important;
    padding: 12px 28px;
    font-family: 'Georama';
  font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    margin-right: 32px;
    box-shadow: 3px 3px 0px #000000;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #ffffff !important;
    background-color: #000 !important;
    border-color: #dee2e6 #dee2e6 #fff;
}
.mbfContainer{
    padding: 0px 5%;
}
.nav-tabs {
    border-bottom: 1px solid #dee2e600 !important;
}
.dayWiseLU{
    max-width: 100%;
    height: 430px;
}
.dayInfoOne, .dayInfoTwo{
    margin: 24px auto;
    text-align: center;
}
.lineupSwiper{
    text-align: center;
}
.partnersTag{
    font-family: 'Georama';
  font-weight: 600;
    font-size:21px;
    line-height: 30px;
    margin-bottom: 16px;
    color: #fff254;
    text-transform: uppercase;
}
.newsCard{
    background: #0007dec6;
    border: 2px solid #ffffff76;
    box-shadow: 4px 4px 0px #000000;
    border-radius: 6px;
    /* width: 300px; */
    padding: 22px;
}
.wrapperBanner {
    width: 100%;
    overflow: hidden;
}
.lineUpSlide{
    max-width: 100%;
    height: auto;
}
.photobanner {
    position: relative;
    height: 380px;
    margin-bottom: 30px;
    display: flex;
    width: 100%;
}

.photobanner img {
    margin: 0px 20px;
}

.photobanner {
    animation: bannermove 90s linear infinite;
}

@keyframes bannermove {
    from {
        left: -3000px;
    }

    to {
        left: 0px;
    }
}
.newsCardTitle{
    font-family: 'Georama';
  font-weight: 600;
    font-style: normal;
    font-size: 22px;
    line-height: 130%;
    /* text-transform: uppercase; */
    color: #fff;
    /* margin-top: 20px; */
}
.newsCardTitle:hover{
    color: #FF4D1F;
    text-decoration: underline;
    transition: .2s all ease-in;
    cursor: pointer;
}
.dividerStyle{
    width: 100vw;
}
.buzzBlock{
    padding: 120px 5%;
}
.instaSpace{
    background: #FFFFFF;
    border: 1px solid #000000;
    box-shadow: 4px 4px 0px #000000;
    border-radius: 34.5px;
    padding: 18px 36px;
    font-family: 'Georama';
  font-weight: 600;
    font-style: normal;
    font-size: 26px;
    line-height: 33px;
    color: #000000;
}
.lineUpMobile{
    padding: 60px 0px;
}
.getTicketsMobile{
    padding: 0px 0px 60px 0px; 
}
.smallInsta{
    max-width: 100%;
    height: auto;
    margin-right: 6px;
}
.alignRight{
    text-align: right;
    margin-top: 20px;
}
.spaceTopPhotos{
    margin-top: 52px;
}
.dummyImgInsta{
    max-width: 100%;
    height: auto;
    box-shadow: 4px 4px 0px #000000;
}
.socialMediaWidget{
    padding: 0px 5% 120px 5%;
}
.dView{
    display: block;
}
.mView{
    display: none;
}
.socialConnectBlock{
    margin: 0px 5% 100px 5%;
    padding: 60px 0px;
    background: rgba(255, 77, 31, 0.09);
    border: 2px solid #000000;
    box-shadow: 4px 4px 0px #000000;
    border-radius: 8px;
    position: relative;
}
.sponsorsBlock{
    margin: 0px 5% 140px 5%;
}
.propLeftC{
    position: absolute;
    bottom: 10px;
    left: 10px;
}
.propRightC{
    position: absolute;
    bottom: 10px;
    right: 10px;
}
.widgetCardStyle{
    background: #FFFFFF;
    border: 2px solid #000000;
    box-shadow: 6px 6px 0px #000000;
    height: 400px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.dummyWidgetHead{
  font-family: 'Georama';
  font-weight: 700;
    font-style: normal;
    font-size: 80px;
    line-height: 81px;
    text-align: center;
    color: #000000;
}
.partnersLogo{
    max-width: 100%;
    height: 105px;
}
.partnersLogombt{
    max-width: 100%;
    height: 58px;
    margin-top: 2px;
}
.partnersLogombms{
    max-width: 100%;
    height: 58px;
    margin-top: 18px;
}
.partnersLogomb{
    max-width: 100%;
    height: 42px;
    margin-top: 8px;
}
.partnersLogombh{
    max-width: 100%;
    height: 90px;
    margin-top: 18px;

}
.mbft{
    margin-bottom: 62px;
}
.moveProps{
    margin-bottom: -2px;
}
.linksBlock{
    margin-top: 82px;
}
.linksNews{
    font-family: 'Georama';
  font-weight: 700;
    font-size: 28px;
    text-align: center;
    color: #fff;
}
.spaceTopView{
    margin-top: 52px;
}
.viewBtn{
    background: #FF4D1F;
    border: 1px solid #000000;
    box-shadow: 3px 5px 0px #000000;
    border-radius: 35px !important;
    font-family: 'Georama';
  font-weight: 700;
    font-style: normal;
    padding: 16px 32px;
    font-size: 18px;
    line-height: 23px;
    color: #fff;
}
.viewBtn:hover{
    box-shadow: 0px 0px 0px #000000;
    transition: .3s all ease-in;
    color: #fff !important;
    text-decoration: none;
    cursor: pointer;    
}
.spaceNewsLinks{
    margin-top: 22px;
}
.smallWeb{
    max-width: 100%;
    height: 30px;
    margin-right: 10px;
}
.moving {
    position: relative;
    overflow: hidden;
    --offset: 20vw;
    --move-initial: calc(-25% + var(--offset));
    --move-final: calc(-50% + var(--offset));
  }
  .moving:hover{
    cursor: pointer;
  }
  .moving-text {
    width: max-content;
    display: flex;
    position: relative;
    transform: translate3d(var(--move-initial), 0, 0);
    animation: scrollingtext 15s linear infinite;
    /*     animation-play-state: paused; */
  }
  
  .moving-text span {
    font-size: 28px;
    color: #000;
    padding: 0 24px;
    font-family: 'Georama';
  font-weight: 600;
  }
  
  @keyframes scrollingtext {
    0% {
      transform: translate3d(var(--move-initial), 0, 0);
    }
  
    100% {
      transform: translate3d(var(--move-final), 0, 0);
    }
  }
  
  /* demo styles - do not copy*/
  .moving {
    color: #ffffff;
    background-size: cover;
  }
.linksNews:hover{
   transition: .3s all ease-in-out;
   color: #fff;
   cursor: pointer;
}
.centerAlign{
    text-align: center;
}
.galleryHomeBlock{
    padding: 120px 5% 120px 5%;
}
.imageGalleryBlock{
    margin-top: 42px;
}


.btnWhite{
    background: #fff;
    border: 1px solid #000000;
    box-shadow: 3px 5px 0px #000000;
    border-radius: 35px !important;
    font-family: 'Georama';
  font-weight: 700;
    font-style: normal;
    padding: 16px 32px;
    font-size: 18px;
    line-height: 23px;
    color: #000;
}

.btnWhite:hover{
    color: #fff !important;
    box-shadow: 0px 0px 0px #000000;
    text-decoration: none;
    background-color: #FF4D1F;
    transition: .3s all ease-in-out;
}
.propLeftC, .propRightC {
    animation: zoom-in-zoom-out-emoji 2s infinite ease-out;
    transform-origin: center;
    transform-box: fill-box;
}

@keyframes zoom-in-zoom-out-emoji {
    0% {
        transform: scale(.8, .8);
    }

    50% {
        transform: scale(1.1, 1.1);
    }

    100% {
        transform: scale(.8, .8);
    }
}
.sparklerStyle{
    animation: leftStar 8s infinite linear;
    transform-origin: center;
    transform-box: fill-box;
    max-width: 100%;
    height: 50px;
    margin-right: 8px;
}

@keyframes leftStar {
    from {
        transform: rotateZ(0deg);
    }

    to {
        transform: rotateZ(360deg);
    }
}

  
  .vert-move {
    -webkit-animation: mover 1s infinite  alternate;
    animation: mover 1s infinite  alternate;
}
@-webkit-keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-30px); }
}
@keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-30px); }
}

.secondProps{
    text-align: right;
    padding: 40px 5%;
}
.SecondAnimate{
    max-width: 100%;
    height: 145px;
}
.logoContainer{
    text-align: center;
}
.bgLineUpHomeCard{
    /* background-color: #3D1152; */
    /* background-image: url('../Assets/lineup/bgLineUp.svg'); */
    background-color: white;
    background-size: cover;
    background-repeat: no-repeat;
    border: 2px solid #000000;
    box-shadow: 8px 8px 0px #000000;
    border-radius: 10px !important;
    padding: 42px 24px 58px 24px;
}
.bandHuntTxt{
    background-color: #00000069;
    padding: 42px 28px;
    border-radius: 8px;
}
.comingSoonTxtDesktopba{
    font-size: 32px;
    color: #fff;
    font-family: 'Georama';
  font-weight: 600;
    text-align: center;
}
/* Media query starts */
@media screen and (max-width: 767px) {
    .bandHuntTxt {
        padding: 36px 16px;
    }
    .comingSoonTxtMobile{
        font-family: 'Georama';
  font-weight: 600;
        font-size: 22px;
        text-align: center;
        color: #ffffff;
    }
    .bandHuntBlock{
        padding: 80px 5%;
    }
    .bandHuntHeadInfo {
        font-size: 48px;
        margin-bottom: 12px;
    }
    .bandHuntParaInfoSub {
        font-family: 'Georama';
  font-weight: 500;
        font-size: 17px;
        margin-bottom: 32px;
        line-height: 150%;
        text-align: left;
    }
    .bandHuntParaInfo {
        font-family: 'Georama';
  font-weight: 500;
        font-size: 17px;
        margin-bottom: 18px;
    }
    .landing_banner {
        position: relative;
        margin-top: 80px;
    }
    .dayWiseLU{
        max-width: 100%;
        height: auto;
    }
    .spaceLineUpSch{
        margin-bottom: 32px;
    }
    .work-muted {
        position: absolute;
        height: 28px;
        width: 28px;
        right: 16px;
        bottom: 2vh;
      }
      .work-muted:hover{
        cursor: pointer;
      }
    .headingMbf{
        font-size: 42px;
    }
    .aboutBlock{
        padding: 60px 5% 10px 5%;
        margin-top: -8px;
    }
    .contentHeight{
        height: auto;
    }
    .aboutFestivalCard {
        background: #FFFFFF;
        border: 2px solid #000000;
        box-shadow: 4px 4px 0px #000000;
        padding: 42px 16px 58px 16px;
    }
    .tabStyle {
        box-shadow: 2px 3px 0px #000000;
        padding: 12px 24px !important;
        font-size: 16px !important;
    }
    .sparklerStyle{
        margin-bottom: 20px;
    }
    .home_para_bold{
      font-family: 'Georama';
  font-weight: 700;
        font-size: 34px;
        line-height: 80%;
        color: #0008f3;
        margin-top: 30px;
        margin-bottom: 24px;
    }
    .btnBlue{
        padding: 12px 26px;
        font-size: 16px;
        box-shadow: 2px 3px 0px #000000;
        font-family: 'Georama';
  font-weight: 600;
    }
    .tickerBlock {
        padding: 18px 0px;
    }
    .BandHuntHead{
        font-size: 80px;
    }
    .BandHuntSmall{
        font-size: 40px;
    }
    .BluesNewsBlock{
        padding: 80px 5%;
    }
    .headingBeta {
        font-size: 42px;
        line-height: 80%;
        margin-bottom: 32px;
    }
    .newsCardTitle{
        font-size: 18px;
    }
    .btnWhite {
        font-family: 'Georama';
  font-weight: 600;
        padding: 14px 28px;
        font-size: 16px;
    }
    .newsCard{
        padding: 18px 14px ;
    }
    .instaSpace{
        font-size: 16px;
        padding: 14px 28px;
        box-shadow: 3px 3px 0px #000;
    }
    .alignRight{
        text-align: center;
    }
    .buzzBlock{
        padding: 80px 5%;
    }
    .dView{
        display: none;
    }
    .mView{
        display: block;
    }
    .moving-text span {
        font-size: 22px;
        padding: 0 14px;
    }
    .centerAlign{
        text-align: center;
    }
    .spaceTopPhotos{
        margin-top: 0px;
        margin-bottom: 12px;
    }
    .dummyImgInsta{
        margin-bottom: 20px;
    }
    /* .MainBanner{
        margin-top: 70px;
    } */
    .dummyWidgetHead {
        font-size: 60px;
        line-height: 53px;
    }
    .mb42{
        margin-top: 42px;
    }
    .widgetCardStyle{
        margin-bottom: 20px;
        height: 360px;
    }
    .moveProps{
        margin-bottom: -1px;
    }
    .linksNews{
        font-size: 22px;
        margin-bottom: 24px;
    }
    .smallWeb{
        height: 22px;
    }
    .spaceNewsLinksM{
        margin-bottom: 12px;
    }
    .spaceNewsLinks{
        margin-top: 0px;
    }
    .viewBtn{
        padding: 12px 26px;
        font-size: 16px;
        box-shadow: 2px 3px 0px #000000;
    }
    .spaceTopView{
        margin-top: 32px;
    }
    .socialMediaWidget{
        padding: 0px 5% 20px 5%;
    }
    .connectIcons{
        height: 38px;
    }
    .propsOneStyle{
            /* height: 200px;
            margin-top: 10px;
            margin-bottom: -20px; */
            display: none;
    }
    .galleryHomeBlock{
        padding-top: 80px ;
        padding-bottom: 80px;
    }
    .SecondAnimate{
        height: 68px;
    }
    .bandHuntAnimate{
        height: 300px;
    }
    .mainStageBlock{
        padding-top: 80px;
        border-bottom: 10px solid #fff254;
    }
    .aboutCardHeading{
        font-size: 40px;
        margin-bottom: 10px;
    }
    .paragraph{
        font-size: 16px;
    }
    .aboutCardHeadMobile{
        font-size: 36px;
        margin-bottom: 10px;
    }
    .comingSoonTxtMobile{
        font-size: 24px;
    }
    .spacePartner{
        margin-bottom: 12px;
    }
    .mhid{
        display: none;
    }
    .partnersLogo{
        max-width: 100%;
        height: auto;
    }
    .partnersLogombt {
        height: 46px;
    }
    .partnersLogombh {
        height: 76px;
    }
    .partnersLogomb {
        height: 36px;
    }
    .partnersTag {
        font-size: 18px;
    }
    .mbft {
        margin-bottom: 0px;
    }
    .mbftmh{
        margin-bottom: 32px;
    }
    .partnersLogombms {
        max-width: 100%;
        height: 48px;
        margin-top: 2px;
    }
}
@media screen and (min-width: 767px) and (max-width: 1023px) {
}
@media screen and (min-width: 1024px){
}