body {
  background-color: #0008f3 !important;
  margin: 0;
  overflow-x: hidden;
  background: transparent url('http://assets.iceable.com/img/noise-transparent.png') repeat 0 0;
  background-repeat: repeat;
}



@keyframes bg-animation {
    0% { transform: translate(0,0) }
    10% { transform: translate(-5%,-5%) }
    20% { transform: translate(-10%,5%) }
    30% { transform: translate(5%,-10%) }
    40% { transform: translate(-5%,15%) }
    50% { transform: translate(-10%,5%) }
    60% { transform: translate(15%,0) }
    70% { transform: translate(0,10%) }
    80% { transform: translate(-15%,0) }
    90% { transform: translate(10%,5%) }
    100% { transform: translate(5%,0) }
}
.scroll-top {
  right: 18px!important;
  height: 50px!important;
  width: 50px;
  bottom: 29px;
  z-index: 12000;
  position: fixed;
  background-color: #FF4D1F !important;
  padding: 10px;
  border-radius: 50%;
  box-shadow: 4px 4px 0px #000000;
}
.scroll-top:hover{
  cursor: pointer;
}
.coming_soon_txt{
font-family: 'Georama';
  font-weight: 700;
    font-size: 160px;
    line-height: 76%;
    letter-spacing: 0.03em;
    color: #fff254;
    margin-bottom: 16px;
    text-align: center;
}
.desktop{
  display: block;
}
.mobile{
  display: none;
}
@media only screen and (max-width: 767px) {
  .scroll-top {
    right: 16px!important;
    height: 46px!important;
    width: 46px;
    bottom: 28px;
    z-index: 12000;
    position: fixed;
    background-color: #FF4D1F !important;
    padding: 10px;
    border-radius: 50%;
    box-shadow: 4px 4px 0px #000000;
  }
  .coming_soon_txt{
      font-size: 80px;
      line-height: 76%;
  }
  .desktop{
    display: none;
}
.mobile{
    display: block;
}
}