.footerTop{
    width: 100vw;
}
.footer{
    background-color: #000;
    padding: 80px 5% 14px 5%;
}
.footerPara{
    font-family: 'Georama';
  font-weight: 500;
    font-style: normal;
    font-size: 24px;
    line-height: 30px;
    color: #FFFFFF;
    text-align: center;
}
.linkFooter{
    color: #ff4d1f;
    text-align: center;
}
.linkFooter:hover{
    text-decoration: underline;
    cursor: pointer;
    color: #ff4d1f !important;
}
.mainHeadFooter{
  font-family: 'Georama';
  font-weight: 700;
    font-style: normal;
    font-size: 42px;
    line-height: 120%;
    color: #fff254;
    text-align: center;
    margin-bottom: 32px;
}
.moveProps {
    background-size: 1500px;
    background-repeat: repeat-x;
    width: 100%;
    height: 81px;
}
.moveProps.slideright-r {
    background-image: url('../Assets/footerTop.svg');
}
.moveProps.slideright-r {
    animation: slideright 300s infinite linear;
    -webkit-animation: slideright 300s infinite linear;
    background-image: url('../Assets/footerTop.svg');
}

@keyframes slideright {
    0%{
        background-position: 0px;
    }
    100%{
        background-position: -10000px;
    }
}
.footerLinksC{
    font-family: 'Georama';
  font-weight: 500;
    font-style: normal;
    font-size: 16px;
    color: #fff254;
   
}
.copyrightTxt{
    font-family: 'Georama';
  font-weight: 500;
    font-style: normal;
    font-size: 16px;
    color: #fff;
    margin-top: 6px;
}
.divider{
    width: 100%;
    color: #ff4d1f;
    background-color: #1f1fff9f;
    margin-top: 40px;
    margin-bottom: 0px;
}
.spacearoundF{
    margin: 0px 10px;
}
.alignCopyrightTxt{
    text-align: right;
}
.copyrightSTop{
    background-color: #000;
    padding: 18px 5% 0px 5%;
    margin-right: 0px !important;
    margin-left: 0px !important;
}
.hyperlinkStyle:hover{
    cursor: pointer;
}
.hyperlinkBlock{
    text-align: center;
    margin-top: 52px;
}
.hyperlinkImgStyle{
    max-width: 100%;
    height: 140px;
}
@media only screen and (max-width: 767px) {
    .mainHeadFooter{
        font-size: 36px;
        line-height: 80%;
        margin-bottom: 10px;
    }
    .footerPara {
        font-size: 16px;
        line-height: 140%;
        padding: 0px 12%;
        font-family: 'Georama';
  font-weight: 500;
    }
    .footer {
        background-color: #000;
        padding: 50px 0px 20px 0px;
    }
    .footerTop{
        margin-bottom: -6px;
    }
    .copyrightTxt{
        font-size: 14px;
        margin-top: 0px;
        margin-bottom: 8px;
        text-align: center;
    }
    .alignCopyrightTxt{
        text-align: center;
    }
    .footerLinksC{
        margin-top: 0px;
        font-size: 14px;
    }
    .hyperlinkImgStyle{
        max-width: 100%;
        height: 100px;
    }
}